import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Subject } from 'rxjs';
import {
  alert_error,
  alert_success,
  alert_warning,
} from 'src/app/functions/alert-function';
import { Almacen_Service } from 'src/app/services/almacen.service';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { ProductoService } from 'src/app/services/producto.service';
import { Regularizar_Stock_Service } from 'src/app/services/regularizar_stock_almacen.service';

@Component({
  selector: 'app-regularizar-stock',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    CardModule,
    DividerModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    FontAwesomeModule,
  ],
  templateUrl: './regularizar-stock.component.html',
  styleUrl: './regularizar-stock.component.css',
})
export class RegularizarStockComponent implements OnInit {
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  productos_dinamico__db: any[][] = [];
  almacen_productos_dinamico__db: any[][] = [];
  eventsSubject: Subject<void> = new Subject<void>();
  almacenes: any[] = [];
  disableButton: boolean = false; // Variable para deshabilitar el botón
  private requestInProgress = false; // Para evitar peticiones duplicadas

  detalles = null;
  almacenes_permisos: any[] = [];
  //////////

  almacen: any = {};
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private _Almacen_Service: Almacen_Service,
    private _AlmacenProducto_Service: AlmacenProducto_Service,
    private _Producto_Service: ProductoService,
    private _Regularizar_Stock: Regularizar_Stock_Service
  ) {}
  almacenes_reportes: any[] = [];
  ngOnInit(): void {
    this.getAlmacenes_Api();

    this.getProductos_Api();
  }

  productos: any[] = [];
  agregarFila() {
    const nuevaFila = { cantidad: 1 };
    this.productos.push(nuevaFila);
  }
  eliminarFila(i: number) {
    this.productos.splice(i, 1);
  }
  getAlmacenProductosByProductoIdAlmacenId_Api(
    producto: DropdownChangeEvent,
    i: number
  ): void {
    const producto_id = producto.value;
    const almacen_id = this.almacen.id_almacen;
    const request_data = {
      producto: producto_id,
      almacen: almacen_id,
    };
    this._AlmacenProducto_Service.getByOther(request_data).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          // this.categorias_dinamico = response_data;
          this.almacen_productos_dinamico__db[i] = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error(
            'No se encontró un array de productos en la respuesta.'
          );
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio', error);
      },
    });
  }
  getAlmacenProductoById_Api(
    almacen_producto: DropdownChangeEvent,
    i: number
  ): void {
    const producto_id = almacen_producto.value;

    if (!producto_id) {
      console.warn('El ID del producto es inválido:', producto_id);
      return;
    }

    this._AlmacenProducto_Service.getByid(producto_id).subscribe({
      next: (response: any) => {
        if (response?.data) {
          this.eventsSubject.next();
        } else {
          console.warn(
            'No se encontraron datos de productos en la respuesta:',
            response
          );
        }
      },
      error: (error) => {
        console.error('Error al obtener los datos del producto:', error);
      },
    });
  }

  getAlmacenes_Api(): void {
		const request_data = {
		  usuario: localStorage.getItem('id_usuario')
		};
		this._Almacen_Service.getByOther(request_data).subscribe({
		  next: (response: any) => {
			const response_data = response.data;
			console.log(response_data);
			if (response_data) {
			  this.almacenes_reportes = response_data;
	
			  // Accediendo al campo id_almacen
			  const idAlmacen = response_data.id_almacen;
			  console.log('ID del almacén:', idAlmacen);
	
			  setTimeout(() => {
				this.eventsSubject.next();
			  }, 200);
			} else {
			  console.error('No se encontró un array de productos en la respuesta.');
			}
		  },
		  error: (error) => {
			console.log("Error al llamar el servicio", error);
		  }
		});
	  }
  
  getProductos_Api(): void {
    this._Producto_Service.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.productos_dinamico__db = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error(
            'No se encontró un array de productos en la respuesta.'
          );
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio', error);
      },
    });
  }
  register__Stock_actualizado() {
    if (!this.validarFormulario()) {
        alert_warning("Debe completar todos los campos obligatorios.");
        return;
    }

    if (this.requestInProgress) return; // Si ya hay una petición en curso, no hacer nada

    this.requestInProgress = true;
    this.disableButton = true; // Deshabilitar el botón al iniciar la petición

    const request_data = {
        almacen_id: this.almacen.id_almacen,
        detalles: this.detalles,
        productos: this.productos,
    };

    this._Regularizar_Stock.create(JSON.parse(JSON.stringify(request_data))).subscribe({
        next: (response: any) => {
            if (response.code === 409) {
                alert_warning('No se ha registrado ningún producto. Stock Agotado');
            } else {
                alert_success('Registrado correctamente');
                this.resetForm();
                this.closeModalEmmit.emit(true);
            }
            this.requestInProgress = false;
            this.disableButton = false; // Habilitar el botón después de recibir respuesta
        },
        error: (err) => {
            const message = err.status === 409
                ? 'Debe rellenar todos los campos'
                : 'ERROR en registrar la venta. No hay stock del Producto';
            alert_error('ERROR', message);

            this.requestInProgress = false;
            this.disableButton = false; // Habilitar el botón en caso de error
        }
    });
}
//resetear formulario
  resetForm() {
    this.almacen.id_almacen = null;
    this.detalles = null;
    this.productos = [];
  }
  //validad formulario
  validarFormulario(): boolean {
    if (!this.almacen.id_almacen) return false;
    if (this.productos.length === 0) return false; 
  
    for (const producto of this.productos) {
      if (!producto.id_producto || !producto.id_almacen_producto || !producto.cantidad) {
        return false; 
      }
    }
  
    return true; 
  }
  cancelarButton(){
    this.resetForm()
    this.closeModalEmmit.emit(true);
  }
}
