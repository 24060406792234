<div class="container mt-4">
    <!-- Selección de almacén -->
    <div class="mb-3">
      <label class="form-label">Seleccione almacén</label>
      <p-dropdown
        inputId="almacen--id"
        class="form-control"
        [options]="almacenes_reportes"
        optionValue="id_almacen"
        optionLabel="nombre"
        [(ngModel)]="almacen.id_almacen"
        [showClear]="true"
        placeholder="Seleccione Almacén"
      ></p-dropdown>
    </div>
  
    <!-- Detalles (opcional) -->
    <div class="mb-3">
      <label class="form-label">Detalles (Opcional)</label>
      <textarea class="form-control" [(ngModel)]="detalles" name="detalles"></textarea>
    </div>
  
    <!-- Botón para agregar productos -->
    <div class="mb-3 text-end">
      <p-button icon="pi pi-plus" (click)="agregarFila()" label="Agregar Producto"></p-button>
    </div>
  
    <!-- Tabla de productos -->
    <div class="mb-3">
      <p-card>
        <table class="table table-striped">
          <thead class="table-dark">
            <tr>
              <th width="40%">Producto</th>
              <th width="20%">Talla</th>
              <th width="10%">Cantidad</th>
              <th width="10%">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let producto of productos; let i = index">
              <td>
                <p-dropdown
                  [options]="productos_dinamico__db"
                  optionValue="id_producto"
                  optionLabel="nombre_largo"
                  [showClear]="true"
                  placeholder="Producto"
                  [(ngModel)]="producto.id_producto"
                  (onChange)="getAlmacenProductosByProductoIdAlmacenId_Api($event, i)"
                  [filter]="true"
                  class="form-control"
                ></p-dropdown>
              </td>
              <td>
                <p-dropdown
                  [options]="almacen_productos_dinamico__db[i]"
                  optionValue="id_almacen_producto"
                  optionLabel="nombre_corto"
                  [showClear]="true"
                  placeholder="Talla"
                  [(ngModel)]="producto.id_almacen_producto"
                  (onChange)="getAlmacenProductoById_Api($event, i)"
                  [filter]="true"
                  class="form-control"
                ></p-dropdown>
              </td>
              <td>
                <p-inputNumber
                  [(ngModel)]="producto.cantidad"
                  mode="decimal"
                  [useGrouping]="false"
                  class="form-control"
                ></p-inputNumber>
              </td>
              <td>
                <p-button
                  icon="pi pi-trash"
                  (click)="eliminarFila(i)"
                  severity="danger"
                  pTooltip="Eliminar"
                  tooltipPosition="bottom"
                ></p-button>
              </td>
            </tr>
          </tbody>
        </table>
      </p-card>
    </div>
  
    <!-- Botones de acción -->
    <div class="text-end">
      <p-button
        label="Cancelar"
        severity="secondary"
        class="me-2"
        (click)="cancelarButton()"
      ></p-button>
  
      <p-button
        label="Registrar"
        severity="success"
        [disabled]="disableButton"
        (click)="register__Stock_actualizado()"
      ></p-button>
    </div>
  </div>
  