import { Injectable } from '@angular/core';
import { CrudService } from './util/crud.service';

import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { urlConstants } from '../constants/url-constants';

import { behaidorInterface } from './interfaces/behaidor-interface';
import { ProductRequest } from '../request/producto/producto.request';
import { ProductResponse } from '../response/productos/producto.response';
import { Regularizar_stock_request } from '../request/regularizar_stock/regularizar_stock_request';
import { Regularizar_stock_response } from '../response/regularizar_stock/regularizar_stock.response';

@Injectable({
    providedIn: 'root',
})
export class Regularizar_Stock_Service
    extends CrudService<Regularizar_stock_request, Regularizar_stock_response>
    implements behaidorInterface<Regularizar_stock_response[]>
{
    private dataSource = new BehaviorSubject<Regularizar_stock_response[]>([]);

    constructor(protected http: HttpClient) {
        super(http, urlConstants.regularizar_stock);
    }

    setValueBehaidor(value: Regularizar_stock_response[]): void {
        this.dataSource.next(value);
    }
    getValueBehaidorSubject() {
        return this.dataSource.getValue();
    }
    clearBehaidorSubject(): void {
        this.dataSource.next([]);
    }
}
