<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ title }}</h4>

</div>
<p-card>
    <div class="header-boton">
        <div class="botton-1">
            <p-button icon="pi pi-plus" (click)="agregarFila()" label="Agregar Productos"></p-button>
        </div>
        <div class="select-1">
            <p-dropdown [options]="almacenes" optionValue="id_almacen" [(ngModel)]="almacen.id_almacen"
                optionLabel="nombre" [showClear]="true" placeholder="Selecciona Almacén"></p-dropdown>
        </div>
    </div>
</p-card>
<p-divider type="solid"></p-divider>
<p-card>
    <table>
        <thead>
            <tr>
                <th width="10%">Producto</th>
                <th width="20%">Talla</th>
                <th width="5%">Cantidad</th>
                <th width="10%">Unidad Medida</th>
                <th width="10%">Precio Compra</th>
                <th width="10%">Precio Venta</th>
                <th width="25%">Detalles</th>
                <th width="10%">Accíon</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let producto of productos; let i = index">
                <td>
                    <p-dropdown [options]="productos_dinamico__db" class="drop-size" optionValue="id_producto"
                    optionLabel="nombre_largo" [showClear]="true" placeholder="Producto"
                    [(ngModel)]="producto.id_producto"
                    (onChange)="getAlmacenProductosByProductoIdAlmacenId_Api($event, i)"></p-dropdown>  
                    
                </td>
                <td>
                    <p-dropdown [options]="almacen_productos_dinamico__db[i]" class="drop-size"
                        optionValue="id_almacen_producto" optionLabel="nombre_corto" [showClear]="true"
                        placeholder="Talla" [(ngModel)]="producto.id_almacen_producto"
                        (onChange)="getAlmacenProductoById_Api($event, i)"></p-dropdown>
                </td>
                <td><input class="input-size" type="text" pInputText [(ngModel)]="producto.cantidad" /></td>
                <td>
                    <p-dropdown [options]="unidad_medida" [(ngModel)]="producto.unidad_medida" optionLabel="name"
                        [showClear]="true" optionValue="code" placeholder="Cantidad" [readonly]="true"></p-dropdown>
                </td>
                <td>
                    <p-inputNumber class="input-size" [(ngModel)]="producto.precio_compra" inputId="minmaxfraction"
                        mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"> </p-inputNumber>
                </td>
                <td>
                    <p-inputNumber class="input-size" [(ngModel)]="producto.precio_venta" inputId="minmaxfraction"
                        mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"
                        [readonly]="true"></p-inputNumber>
                </td>
                <td><input type="text" pInputText [(ngModel)]="producto.detalles" style="width: 90%" /></td>
                <td>
                    <!-- <p-button [raised]="true" pTooltip="Ver" tooltipPosition="bottom" icon="pi pi-eye"
                        severity="info"></p-button> -->
                    <!-- <p-button [raised]="true" pTooltip="Configurar" tooltipPosition="bottom" icon="pi pi-cog"
                            severity="warning"></p-button> -->
                    <p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash"
                        (click)="eliminarFila(i)" severity="danger"></p-button>
                </td>
            </tr>
        </tbody>
    </table>
</p-card>

<p-divider type="solid"></p-divider>

<!-- <div [formGroup]="IngresoAlmacen_Form"> -->
<p-card header="Proveedor">
    <p-selectButton [options]="stateOptions" [(ngModel)]="incluir_proveedor" optionLabel="label"
        optionValue="value"></p-selectButton>


    <table *ngIf="incluir_proveedor === 'SI'">
        <thead>
            <tr>
                <th>Proveedor</th>
                <th>RUC</th>
                <th>Nombre</th>
                <th>Nombre Comercial</th>
                <th>Razon Social</th>
                <th>Celular</th>
                <th>Acción</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <p-dropdown class="drop-size" [options]="proveedores" optionValue="id_proveedor"
                        optionLabel="nombre" [(ngModel)]="proveedor.id_proveedor"
                        (onChange)="getProveedorById_Api($event)" [showClear]="true"
                        placeholder="Proveedor"></p-dropdown>
                </td>
                <td><input class="input-size" type="text" pInputText [(ngModel)]="proveedor.ruc" /></td>
                <td><input class="input-size" type="text" pInputText [(ngModel)]="proveedor.nombre" /></td>
                <td><input class="input-size" type="text" pInputText [(ngModel)]="proveedor.nombre_comercial" /></td>
                <td><input class="input-size" type="text" pInputText [(ngModel)]="proveedor.razon_social" /></td>
                <td><input type="text" pInputText [(ngModel)]="proveedor.celulares" /></td>
                <td>
                    <p-button [raised]="true" pTooltip="Ver" tooltipPosition="bottom" icon="pi pi-eye"
                        severity="info"></p-button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="register-botton">
        <button pButton type="button" class="p-button-success mb-5" icon="pi pi-save" label="Registrar"
        [disabled]="disableButton" (click)="registerProductoAlmacen_Api()"></button>
        <p-button label="Cancelar" (click)="closeModal(true)" [raised]="true" severity="danger"></p-button>
    </div>
</p-card>
<!-- </div> -->
<!-- </div> -->